import axios from 'axios';
import { config } from '../config/common';

let baseURL = config().rest_url; // dev server
export const rootURL = baseURL;

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 150000
  });
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      const auth = token ? `Bearer ${token}` : '';
      config.headers.common['Authorization'] = auth;
      return config;
    },
    (error) => Promise.reject(error),
  );
export default axiosInstance;
