import React from "react";

import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { Switch } from "react-router-dom"
import DashboardLayout from "../layout/DashboardLayout";

import DashboardPage from '../pages/DashboardPage';
import DashboardWelcomePage from '../pages/DashboardWelcomePage';
import SettingPage from '../settings/SettingPage';
const AdminRoutes = ({ children: any }) => {
     return <DashboardLayout>
          <Switch>
               <Route path='/' exact component={DashboardWelcomePage} />
               <Redirect exact from='/dashboard/company/:companyId' to="/dashboard/company/:companyId/homepage=1" />
               <Route path='/dashboard/company/:companyId/:tab=:tabIndex' component={DashboardPage} />
               <Route path='/dashboard/setting' exact component={SettingPage} />
          </Switch>
     </DashboardLayout>
}

export default AdminRoutes;