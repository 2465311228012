import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router-dom';
import { Router } from 'react-router-dom';
import './App.css';
import { LogInPage } from './components/pages/loginpage';
import AdminRoutes from './components/routes/AdminRoutes';
import { RouteGuard } from './components/routes/RouteGuard';
import history from './utils/history';


class App extends Component {

  render() {

    return (
      <Router history={history}>
        <Switch>
          <RouteGuard
            path='/dashboard'
            component={AdminRoutes} routeRedirect={undefined} />
            <Route exact path="/login" component={LogInPage} />
            <Route exact path="/" component={LogInPage} />
        </Switch>
      </Router>

    );
  }
}

export default App;
