import React, {  Fragment,  useEffect, useState } from 'react';
import { MDBListGroup, MDBListGroupItem, MDBIcon } from 'mdbreact';
import { NavLink } from 'react-router-dom';
import './sideNavigation.css';
import {getAllCompany} from "../../Api/companyApi"
import { useRouter } from '../../hooks/router/userRouter';

const TopNavigation = () => {
  const [data, setData] = useState({ companies: [] });
 
  useEffect( () => {
    const fetchData = async () => {
      const result = await getAllCompany();
      setData({ companies : result});
    };
    fetchData();
  }, []);


  const renderCompanyList = () => {
    return (
      <Fragment>
        {data.companies && data.companies.slice(0, 1).map((company: any, index: any) => (
          <div key={index}>
            <MDBListGroup className="list-group-flush">
              <NavLink to={`/dashboard/company/${company.id}`} activeClassName="activeClass" key={index} >
                <MDBListGroupItem >
                  {company.name}
                </MDBListGroupItem>
              </NavLink>
            </MDBListGroup>
          </div>

        ))}
      </Fragment>
    )
  }

  return (
      <div className="sidebar-fixed position-fixed scrollbar scrollbar-primary" >
        <label htmlFor="defaultFormCompanyListEx" className="grey-text">
          Company List
          </label>
        {renderCompanyList()}
        <NavLink exact={true} to={`/dashboard/setting`} activeClassName="activeClass" >
          <MDBListGroupItem>
            Settings{" "}<i className="fas fa-cogs"></i>
          </MDBListGroupItem>
        </NavLink>
 
      </div>
  )
  

}

export default TopNavigation;