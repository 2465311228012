import React, { Component, Fragment } from "react";
import { MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBDataTable } from 'mdbreact';
import { getContactFromCompanyId, addContactToCompanyId, deleteContactApi, updateContactToCompanyId } from '../../../Api/api';

import { getAllContacts } from "../../../Api/contactApi";
import SpinnerPage from "../../pages/SpinnerPage";
import './contact.scss';

import { successToast, errorToast } from '../../../utils/toast'
import { string } from "prop-types";
import ContactModal from "./contact-modal";
import ContactDataTable from "./contact-data-table";


interface ContactInterfaceProps {
  id: string,
  fb_url: string,
  insta_url: string,
  youtube_url: string,
  location: string,
  mapUrl: string,
  companyName: string,
  designation: string,
  cell_number: string,
  telephone_number: string,
  website_url: string


}


interface ContactInterfaceState {
  contact: ContactInterface,
  contacts: [],
  modal: boolean,
  edit: boolean,

  optionActive: string,
  optionActiveId: string,
  loading: boolean
}


interface ContactInterface {
  id: string,
  fb_url: string,
  insta_url: string,
  youtube_url: string,
  location: string,
  mapUrl: string,
  companyName: string,
  designation: string,
  cell_number: string,
  telephone_number: string,
  website_url: string

}


export default class ContactFormPage extends Component<any, ContactInterfaceState>{
  constructor(props: any) {
    super(props)
    this.state = {
      contact: {
        id: '',
        fb_url: '',
        location: '',
        insta_url: '',
        youtube_url: '',
        mapUrl: '',
        companyName: '',
        designation: '',
        cell_number: '',
        telephone_number: '',
        website_url: ''
      },
      contacts: [],
      modal: false,
      edit: false,
      optionActive: '',
      optionActiveId: '',
      loading: false,
    };
    this.clear = this.clear.bind(this);
    this.toogleActiveOption = this.toogleActiveOption.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    getAllContacts()
      .then((res: any) => {
        console.log("Success :: ", res);
        this.setState({ contacts: res, loading: false })
      })
      .catch((err: any) => {
        alert('Error to create a company' + err)
      });
  }

  toogleActiveOption = (id: string) => {
    this.setState({
      optionActive: this.state.optionActive == '' ? 'active' : '',
      optionActiveId: id
    })

  }



  clear() {
    this.setState({
      contact: {
        ...this.state.contact,
        fb_url: '',
        location: '',
        insta_url: '',
        youtube_url: '',
        companyName: '',
        designation: '',
        cell_number: '',
        telephone_number: '',
        website_url: ''
      }

    });
  }




  render() {
    

    const data = {
      columns: [
       
        {
          title: 'Location',
          field: 'location',
        },
        {
          title: 'Website',
          field: 'website_url',
        },
      ],
    }

    return (

      this.state.loading ? <SpinnerPage /> :


        <div>

          <div className="contact-table">
            <ContactDataTable
              columns={data.columns}
              rows={this.state.contacts}
              company={this.props.company && this.props.company[0]}
            />

          </div>

        </div>
    );
  };
}