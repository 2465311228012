import React, { Fragment, useState, Component } from "react";
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBBtn, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBInput } from "mdbreact";
import { postWorkCategoryApi, updateWorkCategoryApi} from "../../../../../../Api/workCategory";
import { successToast, errorToast } from "../../../../../../utils/toast";
import CompanyContext from "../../../../homepage/context/companyContext";

export default class WorkCategoryModal extends Component<any, any>{

    static contextType = CompanyContext;

    constructor(props: any) {
        super(props)
        this.state = {
            title: "",
            description: "",
            rowData: this.props.rowData,
        };
        this.handleInput = this.handleInput.bind(this);
        this.addWorkCategory = this.addWorkCategory.bind(this);
        this.updataWorkCategory = this.updataWorkCategory.bind(this);
    }

    handleInput = (e: any) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    updataWorkCategory = () => {
        let value = this.context;
        const title = this.state.title == "" ? this.props.rowData.title : this.state.title;
        const description = this.state.description == "" ? this.props.rowData.description : this.state.description;

        const form = new FormData();
        form.append("id", this.props.rowData.id);
        form.append("title", title);
        form.append("company_id", value);
        form.append("description", description);

        updateWorkCategoryApi(this.props.rowData.id, form)
            .then((res: any) => {

                successToast('Company Work Category Updated Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Work Category Update On Error');
            });
    }

    addWorkCategory = () => {
        let value = this.context;
        const title = this.state.title == "" ? this.props.rowData.title : this.state.title;
        const description = this.state.description == "" ? this.props.rowData.description : this.state.description;
        const form = new FormData();
        form.append("title", title);
        form.append("company_id", value);
        form.append("description", description);

        postWorkCategoryApi(form)
            .then((res: any) => {
                successToast('Company Work Category Created Sucessfully');
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            })
            .catch((err: any) => {
                errorToast('Company Work Category Create On Error');
            });
    }


    render() {
        const openStatus = this.props.open;
        const toggle = this.props.toogle;
        const rowData = this.props.rowData;
        return (
            <Fragment>
                <br />

                <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
                    <MDBModalHeader toggle={toggle}>Add New Work Category </MDBModalHeader>
                    <MDBModalBody>
                        <MDBContainer className="work_category_title">
                            <MDBRow>
                                <MDBCol md="12" >
                                    <MDBInput
                                        size="sm"
                                        outline
                                        label="Work Category Title"
                                        name="title"
                                        valueDefault={rowData && rowData.title}
                                        onInput={this.handleInput}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>


                        <MDBContainer className="work_category_description">
                            <MDBRow>
                                <MDBCol md="12" className="url">
                                    <MDBInput
                                        size="sm"
                                        type="textarea"
                                        label="Work Category Description"
                                        outline
                                        name="description"
                                        onInput={this.handleInput}
                                        valueDefault={rowData && rowData.description}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="danger" onClick={() => { toggle(); }}>Close</MDBBtn>
                        {this.props.edit ?

                            <MDBBtn
                                color="primary"
                                outline
                                type="submit"
                                className='w-30'
                                onClick={() => { toggle(); this.updataWorkCategory() }}
                            >
                                Update
                                <MDBIcon far icon="plus-square" className="ml-2" />
                            </MDBBtn> :
                            <MDBBtn
                                color="primary"
                                outline
                                type="submit"
                                className='w-30'
                                onClick={() => { toggle(); this.addWorkCategory() }}
                            >
                                Add
                    <MDBIcon far icon="plus-square" className="ml-2" />
                            </MDBBtn>
                        }
                    </MDBModalFooter>
                </MDBModal>

            </Fragment>
        )
    }

}
