import axios from '../Axios/index';

export const postCompany = async(data: any) => {
    return await axios.request({
        url: `/api/contact`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllContacts = async () => {
    return await axios.request({
        url: `/api/contact`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const updateContactApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/contact/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
        return res.data
    })

}

export const getContact = async(id: any) => {
    return await axios.request({
        url: `/api/contact/${id}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })

}

export const deleteContact = async (contactId: any) => {
    return await axios.request({
        url: `/api/contact/${contactId}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}