import React, { Component, Fragment } from "react";
import {
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBDataTable
} from "mdbreact";
import {
  getContactFromCompanyId,
  addContactToCompanyId,
  deleteContactApi,
  updateContactToCompanyId
} from "../../../Api/api";

import {
  getAllHomePageApi,
  getAllHomePageByCompanyApi,
  deleteHomePageApi
} from "../../../Api/homePageApi";
import SpinnerPage from "../../pages/SpinnerPage";

import { successToast, errorToast } from "../../../utils/toast";

import HomeDataTable from "./home-data-table";

import CompanyContext from "./context/companyContext";

interface HomeInterfaceProps {
  id: string;
  project_title: string;
  project_description: string;
  image_url: string;
  video_url: string;
}

interface HomeInterfaceState {
  home: HomeInterface;
  homePages: [];
  modal: boolean;
  edit: boolean;

  optionActive: string;
  optionActiveId: string;
  loading: boolean;
}

interface HomeInterface {
  id: string;
  project_title: string;
  project_description: string;
  image_url: string;
  video_url: string;
}

export default class HomePageTabs extends Component<any, HomeInterfaceState> {
  static contextType = CompanyContext;

  constructor(props: any) {
    super(props);
    this.state = {
      home: {
        id: "",
        project_title: "",
        project_description: "",
        image_url: "",
        video_url: ""
      },
      homePages: [],
      modal: false,
      edit: false,
      optionActive: "",
      optionActiveId: "",
      loading: false
    };
    this.clear = this.clear.bind(this);
    this.toogleActiveOption = this.toogleActiveOption.bind(this);
    this.deleteHomePage = this.deleteHomePage.bind(this);
  }

  componentDidMount() {
    let value = this.context;

    this.setState({ loading: true });
    getAllHomePageByCompanyApi(value)
      .then((res: any) => {
        console.log("Success :: ", res);
        this.setState({ homePages: res, loading: false });
      })
      .catch((err: any) => {
        alert("Error to create a company" + err);
      });
  }

  deleteHomePage = (rowdata: any) => {
    if (window.confirm("Are you sure you want to delete this home page?")) {
      deleteHomePageApi(rowdata.id)
        .then((res: any) => {
          successToast("Company Home Page Deleted Sucessfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err: any) => {
          alert("Error to delete a company home page" + err);
        });
    }
  };

  toogleActiveOption = (id: string) => {
    this.setState({
      optionActive: this.state.optionActive == "" ? "active" : "",
      optionActiveId: id
    });
  };

  clear() {
    this.setState({
      home: {
        ...this.state.home,
        project_title: "",
        project_description: "",
        image_url: "",
        video_url: ""
      }
    });
  }

  render() {
    const data = {
      columns: [
        {
          title: "Project Title",
          field: "title"
        },
        {
          title: "Project Description",
          field: "description"
        }
      ]
    };

    const homepages = this.state.homePages;
    const company = this.props.company && this.props.company[0];

    return this.state.loading ? (
      <SpinnerPage />
    ) : (
      <div>
        <div className="contact-table">
          <HomeDataTable
            columns={data.columns}
            rows={homepages}
            company={company}
            deleteHomePage={this.deleteHomePage}
          />
        </div>
      </div>
    );
  }
}
