import React, { useState } from 'react';
import MaterialTable, { Column } from 'material-table';
import { Button } from '@material-ui/core';
import ContactModal from '../contact-modal';

interface Row {
  name: string;
  surname: string;
  birthYear: number;
  birthCity: number;
}

interface TableState {
  columns: Array<Column<Row>>;
  data: Row[];
}

export default function MaterialTableDemo(props : any) {

  const [addStatus, setAddStatus] = React.useState(false);

  const toggleAddStatus  = () => {
    setAddStatus(!addStatus);
  }
  const columns = props.columns;
  const rows = props.rows;

  const [editStatus, setEditStatus] = React.useState(false);

  const toggleEditStatus  = () => {
    setEditStatus(!editStatus);
  }

  const [rowData, setRowData] = React.useState<any>();


  const [state, setState] = React.useState<any>({
    columns: props.columns,
    data: props.rows,
  });

  const {company} = props;

  return (
    <div>
     
    <MaterialTable
      title="Contact List"
      columns={state.columns}
      data={state.data}
      actions={[
        {
          icon: 'add',
          tooltip: 'Add',
          isFreeAction: true,
          onClick: (event) => {
            setAddStatus(true);
          }
        },
        rowData => ({
          icon: 'delete',
          tooltip: 'Delete',
          onClick: (event, rowData) =>{
            
          },
          disabled: false
        }),
        rowData => ({
          icon: 'edit',
          tooltip: 'Edit',
          onClick: (event, rowData) => {
            setRowData(rowData);
            setEditStatus(true);

          },
          disabled: false
        })
      ]}
      options={{
        actionsColumnIndex: -1,
        exportButton: true,
        filtering: true
      }}
    />
    {addStatus ? <div>  <ContactModal open={addStatus} toogle={toggleAddStatus} company={company}/></div>
    : ""}
    {editStatus ? <ContactModal open={editStatus} toogle={toggleEditStatus} company={company} rowData={rowData} edit={true}/>
    : ""}

    
    </div>
  );
}