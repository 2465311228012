import React, { useState } from 'react';
import MaterialTable, { Column, MTableToolbar } from 'material-table';
import { Button } from '@material-ui/core';
import CompanyModal from '../modal';
import { MDBBreadcrumb } from 'mdbreact';
import { MDBBreadcrumbItem } from 'mdbreact';

interface Row {
  name: string;
  surname: string;
  birthYear: number;
  birthCity: number;
}

interface TableState {
  columns: Array<Column<Row>>;
  data: Row[];
}

export default function MaterialTableDemo(props : any) {

  const [editStatus, setEditStatus] = React.useState(false);

  const toggleEditStatus  = () => {
    setEditStatus(!editStatus);
  }

  const [rowData, setRowData] = React.useState<any>();


  const [state, setState] = React.useState<any>({
    columns: props.columns,
    data: props.rows,
  });

  return (
    <div>
     
    <MaterialTable
      title="Company List"
      columns={state.columns}
      data={state.data}
      actions={[
        rowData => ({
          icon: 'edit',
          tooltip: 'Edit',
          onClick: (event, rowData) => {
            setRowData(rowData);
            setEditStatus(true);

          },
          disabled: false
        })
      ]}
      options={{
        actionsColumnIndex: -1
      }}
      // components={{
      //   Toolbar: props => (
      //     <div>
      //       <MTableToolbar {...props} />
      //       <MDBBreadcrumb>
      //   <MDBBreadcrumbItem ><a href="/"> CompanyList</a></MDBBreadcrumbItem>
      //   <MDBBreadcrumbItem>Details</MDBBreadcrumbItem>
      //   <MDBBreadcrumbItem active>Data</MDBBreadcrumbItem>
      // </MDBBreadcrumb>
      //     </div>
      //   ),
      // }}
    />
    {editStatus ? <CompanyModal open={editStatus} toogle={toggleEditStatus} rowData={rowData}/>
    : ""}

    
    </div>
  );
}