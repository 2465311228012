import React, { Component, useState } from 'react'
import { MDBCol, MDBRow, MDBBtn, MDBIcon, MDBNav, MDBNavItem, MDBNavLink, MDBTabContent, MDBTabPane, MDBContainer } from 'mdbreact';
import { getCompany, getAllCompany, postCompany } from '../../Api/api';
import CompanySettingPage from './CompanySetting';

import {successToast, errorToast} from '../../utils/toast';


const SettingPage = () =>{
  const [activeItem, setActiveItem] = useState('company_setting');

  const toggle = (tab : string) => {

  }
    return (
      <div>

        <MDBNav className="nav-tabs">

          <MDBNavItem>
            <MDBNavLink to="#" active={activeItem === 'company_setting'} onClick={toggle('company_setting')} role="tab" >
              Company
                </MDBNavLink>
          </MDBNavItem>

        </MDBNav>
        <MDBTabContent activeItem={activeItem} >
          {activeItem === '' &&
            <>
              <MDBTabPane tabId='' role="tabpanel">
                <MDBContainer>
                  <MDBRow>
                    Hello
                              </MDBRow>
                </MDBContainer>
              </MDBTabPane>
            </>
          }
          {activeItem === 'company_setting' &&
            <>
              <MDBTabPane tabId='company_setting' role="tabpanel">

                <CompanySettingPage loading={false} />
              </MDBTabPane>
            </>
          }
        </MDBTabContent>

      </div>
    );
}


export default SettingPage;