import axios from '../Axios/index';

export const postHomePageApi= async(data: any) => {
    return await axios.request({
        url: `api/homepage`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllHomePageApi = async () => {
    return await axios.request({
        url: `/api/homepage`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllHomePageByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/homepage/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const updateHomePageApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/homepage/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'application/json'}
    }).then((res: any) => {
        return res.data
    })

}

export const deleteHomePageApi= async (homepage_id: any) => {
    return await axios.request({
        url: `/api/homepage/${homepage_id}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}