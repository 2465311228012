import {
  MDBIcon,
  MDBBtn,
  MDBModalFooter,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
} from "mdbreact";
import React, { Fragment } from "react";
import Gallery from "react-grid-gallery";

import "./index.scss";

import copy from "copy-to-clipboard";
import Dropzone from "react-dropzone";
import { showAllAboutByCompanyId, updateAboutApi } from "../../../../Api/api";
import { successToast } from "../../../../utils/toast";
import { config } from "../../../../config/common";
import SpinnerPage from "../../../pages/SpinnerPage";

const pageSize = 12;

export default class AboutTeamPage extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentImage: 0,
      images: [],
      lowIndex: 0,
      maxIndex: 0,
      currentPage: 1,
      photo: "",
      loading: true,
    };

    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.copyToClipBoard = this.copyToClipBoard.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

    this.createImage = this.createImage.bind(this);
    this.onDropFiles = this.onDropFiles.bind(this);
    this.toggle = this.toggle.bind(this);

    this.clear = this.clear.bind(this);
    this.submitHandler = this.submitHandler.bind(this);

    this.renderUploadImage = this.renderUploadImage.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    showAllAboutByCompanyId(this.props.companyId.companyId)
      .then((res: any) => {
        this.setState({ photo: res.abouts[0].src });
        this.setState({ loading: false });
      })
      .catch((err: any) => {
        alert("Error to get About " + err);
      });
    // const { companyId } = this.props;
    // getAllCompanyMedia(companyId)
    //     .then(res => {
    //         this.setState({
    //             images: res.data.map((image: any) => ({
    //                 ...image,
    //                 src: config().rest_url + '/' + image.src,
    //                 thumbnail: config().rest_url + '/' + image.thumbnail,
    //                 thumbnailWidth: Number.parseInt(image.thumbnailWidth),
    //                 thumbnailHeight: Number.parseInt(image.thumbnailHeight),
    //             })),
    //             maxIndex: res.data.length
    //         })
    //     })
    //     .catch(err => {
    //         console.log('Error while get company for introduction ', err)
    //     })
  }

  onCurrentImageChange(index: any) {
    this.setState({ currentImage: index });
  }

  deleteImage() {
    if (window.confirm(`Are you sure ?`)) {
      var images = this.state.images;
      const image = images[this.state.currentImage];
      var source = image.src;
      var data = { source: source };
      // deleteImageApi(data);

      console.log("Image Deleted!");
    }
  }

  clear() {
    this.setState({ image: "" });
  }

  copyToClipBoard() {
    var images = this.state.images;
    const image = images[this.state.currentImage];
    var source = image.src;
    copy(source);
  }

  _next(highIndex: any) {
    this.setState({
      lowIndex: highIndex,
      currentPage: this.state.currentPage + 1,
    });
  }

  _prev(lowIndex: any) {
    this.setState({
      lowIndex: lowIndex - 6,
      currentPage: this.state.currentPage - 1,
    });
  }

  createImage(file: File) {
    let reader = new FileReader();

    reader.onload = (e) => {
      this.setState({
        baImage: reader.result as string,
        image: file,
      });
    };
    reader.readAsBinaryString(file);
  }

  onDropFiles(files: File[]) {
    if (!files.length) return;

    this.createImage(files[0]);
  }

  toggle = () => {
    this.clear();
    this.setState({
      modal: !this.state.modal,
    });
  };

  submitHandler = (event: any) => {
    event.preventDefault();
    const aboutId = this.props.companyId.company.abouts[0].id;
    const companyId = this.props.companyId.company.abouts[0].company_id;
    const src = this.props.companyId.company.abouts[0].src;
    const companyDescription = this.props.companyId.company.abouts[0].description;
    // this.setState({ loading: true });
    const form = new FormData();
    form.append("company_id", companyId);
    form.append("description", companyDescription);
    form.append("src", src);
    form.append("image", this.state.image as File);
    form.append("company_Id", this.props.companyId);
    this.clear();
    this.toggle();
    updateAboutApi(aboutId, form).then((res) => {
      successToast("About updated Sucessfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
    // postMedia(this.props.companyId, form)
    //   .then((res: any) => {
    //     this.setState({ loading: false });
    //     successToast('Image uploaded Sucessfully');
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 2000);
    //   })
    //   .catch((res: any) => {
    //     errorToast('Error while uploading image : ' + res);
    //   });
  };
  renderUploadImage() {
    return (
      <Fragment>
        <br />
        <div className="sample-img">
          <img
            src={config().rest_url + "/" + `${this.state.photo}`}
            alt="team-image"
          />
        </div>
        <MDBBtn onClick={this.toggle} className="banner-upload w-30">
          Upload New Image
          <MDBIcon far icon="paper-plane" className="ml-2" />
        </MDBBtn>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}>
            {" "}
            Upload New Image
          </MDBModalHeader>
          <MDBModalBody>
            <>
              {!this.state.image && (
                <>
                  <Dropzone onDrop={this.onDropFiles} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div
                          {...getRootProps()}
                          className="card card-body view file-upload"
                        >
                          <div className="card-text file-upload-message">
                            <i className="fas fa-cloud-upload-alt"></i>
                            <input {...getInputProps()} />
                            <p>
                              Drag 'n' drop some files here, or click to select
                              files
                            </p>
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </>
              )}
              {this.state.image && (
                <>
                  <div>
                    <img
                      src={URL.createObjectURL(this.state.image)}
                      alt="thumbnail"
                      className="img-thumbnail"
                      style={{ width: "500px" }}
                    ></img>
                  </div>
                </>
              )}
            </>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="danger"
              onClick={() => {
                this.toggle();
                this.clear();
              }}
            >
              Close
            </MDBBtn>
            <MDBBtn
              color="primary"
              outline
              type="submit"
              className="w-30"
              onClick={this.submitHandler}
            >
              Upload
              <MDBIcon far icon="plus-square" className="ml-2" />
            </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </Fragment>
    );
  }

  render() {
    const { lowIndex, currentPage, images } = this.state;
    const highIndex = lowIndex + pageSize;
    const slicedImages = images.slice(lowIndex, highIndex);
    return (
      <>
        {this.state.loading ? (
          <SpinnerPage />
        ) : (
          <>
            {this.renderUploadImage()}
            <div className="media-pagination">
              Page:
              <button
                disabled={lowIndex <= 0}
                className="btn noborder"
                onClick={() => {
                  this._prev(lowIndex);
                }}
              >
                <MDBIcon icon="chevron-left" />
                Prev
              </button>
              {currentPage}/{Math.ceil(images.length / pageSize)}
              <button
                disabled={highIndex > images.length - 1}
                className="btn noborder"
                onClick={() => {
                  this._next(highIndex);
                }}
              >
                Next
                <MDBIcon icon="chevron-right" />
              </button>
            </div>
          </>
        )}

        <Gallery
          key={slicedImages}
          images={slicedImages}
          enableImageSelection={false}
          currentImageWillChange={this.onCurrentImageChange}
          customControls={[
            <button
              className="close_1x3s325"
              style={{ color: "white" }}
              key="deleteImage"
              onClick={this.deleteImage}
            >
              <MDBIcon far icon="trash-alt" />
            </button>,

            <button
              className="copy"
              style={{ color: "white" }}
              key="deleteImage"
              onClick={this.copyToClipBoard}
            >
              <MDBIcon far icon="copy" />
            </button>,
          ]}
        />
      </>
    );
  }
}
