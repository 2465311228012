import axios from '../Axios/index';

export const postWorkCategoryApi= async(data: any) => {
    return await axios.request({
        url: `api/work_category`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllWorkCategoryApi = async () => {
    return await axios.request({
        url: `/api/work_category`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllWorkCategoryByCompanyApi = async (company_id: String) => {
    return await axios.request({
        url: `/api/work_category/company/${company_id}`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const updateWorkCategoryApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/work_category/${id}`,
        method: 'post',
        data: data,
        headers: {'Content-Type': 'application/json'}
    }).then((res: any) => {
        return res.data
    })

}

export const deleteWorkCategoryApi= async (work_category_id: any) => {
    return await axios.request({
        url: `/api/work_category/${work_category_id}`,
        method: 'delete'
    }).then((res: any) => {
        return res.data
    })

}