import React, { Fragment, useState, Component } from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBBtn,
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdbreact";
import {
  postWorkCategoryApi,
  updateWorkCategoryApi,
} from "../../../../../../Api/workCategory";
import { successToast, errorToast } from "../../../../../../utils/toast";
import CompanyContext from "../../../../homepage/context/companyContext";
import Select from "react-select";
import { postWorkApi, updateWorkApi } from "../../../../../../Api/work";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { config } from "../../../../../../config/common";
import Dropzone from "react-dropzone";
import Gallery from "react-grid-gallery";

export default class WorkModal extends Component<any, any> {
  static contextType = CompanyContext;

  constructor(props: any) {
    super(props);
    this.state = {
      title: "",
      description: "",
      category_id: null,
      images: [],
      rowData: this.props.rowData,
      editorState: EditorState.createEmpty(),
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addWork = this.addWork.bind(this);
    this.createImage = this.createImage.bind(this);
    this.onDropFiles = this.onDropFiles.bind(this);
    this.updataWork = this.updataWork.bind(this);
  }

  componentDidMount() {
    const { rowData } = this.props;
    if (rowData !== undefined && rowData.description) {
      if (
        this.props.rowData.description.replace(/(<([^>]+)>)/gi, "").trim() != ""
      ) {
        const contentBlock = convertFromHTML(this.props.rowData.description);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks,
            contentBlock.entityMap
          );
          const editorState = EditorState.createWithContent(contentState);
          this.onEditorStateChange(editorState);
        }
      }
    }
  }

  onEditorStateChange: any = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
  };

  handleInput = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChange = (selectedOption: any) => {
    this.setState({
      category_id: selectedOption.value,
    });
  };

  styleSmall() {
    return {
      height: 142.9,
      width: 199.5,
    };
  }

  updataWork = () => {
    let value = this.context;
    const title =
      this.state.title == "" ? this.props.rowData.title : this.state.title;
    const description = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    const category_id =
      this.state.category_id == null
        ? this.props.rowData.category_id
        : this.state.category_id;
    const form = new FormData();
    form.append("id", this.props.rowData.id);
    form.append("title", title);
    form.append("company_id", value);
    form.append("category_id", category_id);
    form.append("description", description);
    form.append("image", this.state.image as File);
    updateWorkApi(this.props.rowData.id, form)
      .then((res: any) => {
        successToast("Company Work Updated Sucessfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err: any) => {
        errorToast("Company Work Update On Error");
      });
  };

  addWork = () => {
    let value = this.context;
    const title =
      this.state.title == "" ? this.props.rowData.title : this.state.title;
    const description = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    const category_id =
      this.state.category_id == null
        ? this.props.rowData.category_id
        : this.state.category_id;
    const form = new FormData();
    form.append("title", title);
    form.append("company_id", value);
    form.append("category_id", category_id);
    form.append("description", description);
    form.append("image", this.state.image as File);
    postWorkApi(form)
      .then((res: any) => {
        successToast("Company Work Created Sucessfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err: any) => {
        errorToast("Company Work Create On Error");
      });
  };

  createImage(file: File) {
    let reader = new FileReader();

    reader.onload = (e) => {
      this.setState({
        baImage: reader.result as string,
        image: file,
      });
    };
    reader.readAsBinaryString(file);
  }

  onDropFiles(files: File[]) {
    if (!files.length) return;

    this.createImage(files[0]);
  }

  render() {
    const openStatus = this.props.open;
    const toggle = this.props.toogle;
    const rowData = this.props.rowData;
    const workCategories = this.props.workCategories;

    return (
      <Fragment>
        <br />

        <MDBModal isOpen={openStatus} toggle={toggle} size="lg">
          <MDBModalHeader toggle={toggle}>
            Add New Work Category{" "}
          </MDBModalHeader>
          <MDBModalBody>
          {this.props.edit && 
            <MDBContainer className="work_image">
              <MDBRow>
                <MDBCol md="12">
                  {/* {workImages.length && (
                                    <img
                                    key={workImages[0].src}
                                    src={workImages[0].src}
                                    alt="team-image"
                                    style={{ objectFit: "cover", height: "10em" }}
                                  />
                                )} */}
                  {rowData.src ? (
                    <Gallery
                      images={[
                        {
                          src: config().rest_url + '/' + rowData.src,
                          thumbnail: config().rest_url + '/' + rowData.thumbnail,
                        },
                      ]}
                      enableImageSelection={false}
                      backdropClosesModal={true}
                      enableLightbox={true}
                      tileViewportStyle={this.styleSmall}
                      thumbnailStyle={this.styleSmall}
                      margin={2.5}
                    />
                  ) : (
                    <p>No image available</p>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            }
            <MDBContainer className="work_project_title">
              <MDBRow>
                <MDBCol md="12">
                  <MDBInput
                    size="sm"
                    outline
                    label="Project Title"
                    name="title"
                    valueDefault={rowData && rowData.title}
                    onInput={this.handleInput}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>

            <MDBContainer className="work_category_select">
              <MDBRow>
                <MDBCol md="12">
                  <Select
                    options={workCategories}
                    name="category_id"
                    placeholder="Select Work Category"
                    // defaultValue={workCategories[0]}
                    defaultValue={
                      rowData &&
                      workCategories[
                        workCategories.findIndex(
                          (x: any) => x.value === rowData.category_id
                        )
                      ]
                    }
                    onChange={this.handleChange}
                    styles={{
                      menu: (provided, state) => ({
                        ...provided,
                        zIndex: 1000,
                      }),
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <br />
            <MDBContainer className="work_project_description">
              <MDBRow>
                <MDBCol md="12" className="url">
                  {/* <MDBInput
                                        size="sm"
                                        type="textarea"
                                        label="Project Description"
                                        outline
                                        name="description"
                                        onInput={this.handleInput}
                                        valueDefault={rowData && rowData.description}
                                    /> */}
                  <Editor
                    editorState={this.state.editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="form-control"
                    onEditorStateChange={this.onEditorStateChange}
                    editorStyle={{ height: "150px" }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "list",
                        "textAlign",
                        "colorPicker",
                        "link",
                        "embedded",
                        "emoji",
                        "image",
                        "remove",
                        "history",
                      ],
                      fontFamily: {
                        options: [
                          "Arial",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                        ],
                      },
                    }}
                  />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
            <MDBContainer className="work_project_image" style={{ marginTop: "20px"}}>
              <MDBRow>
                <MDBCol md="12">
                  {!this.state.image && (
                    <>
                      <Dropzone onDrop={this.onDropFiles} multiple={false}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="card card-body view file-upload"
                            >
                              <div className="card-text file-upload-message">
                                <i className="fas fa-cloud-upload-alt"></i>
                                <input {...getInputProps()} />
                                <p>
                                  Drag 'n' drop some files here, or click to
                                  select files
                                </p>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </>
                  )}
                  {this.state.image && (
                    <>
                      <MDBContainer>
                        <img
                          src={URL.createObjectURL(this.state.image)}
                          alt="thumbnail"
                          className="img-thumbnail"
                          style={{ objectFit: "cover", height: "10em" }}
                        ></img>
                      </MDBContainer>
                    </>
                  )}
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="danger"
              onClick={() => {
                toggle();
              }}
            >
              Close
            </MDBBtn>
            {this.props.edit ? (
              <MDBBtn
                color="primary"
                outline
                type="submit"
                className="w-30"
                onClick={() => {
                  toggle();
                  this.updataWork();
                }}
              >
                Update
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            ) : (
              <MDBBtn
                color="primary"
                outline
                type="submit"
                className="w-30"
                onClick={() => {
                  toggle();
                  this.addWork();
                }}
              >
                Add
                <MDBIcon far icon="plus-square" className="ml-2" />
              </MDBBtn>
            )}
          </MDBModalFooter>
        </MDBModal>
      </Fragment>
    );
  }
}
