import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import {
    MDBContainer, MDBRow, MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBInput,
    MDBBtn
} from 'mdbreact';

import './login.css'
import { login, setToken } from '../../Api/api';
import { appendFile } from 'fs';

export function LogInPage() {
   
    const [errorMessages, setErrorMessages] = useState({ name: '', message: '' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email,setEmail] = useState<string>('')
    const [password,setPassword] = useState<string>('')
    const history = useHistory();
    const [authenticated, setauthenticated] = useState<string>('');
    useEffect(() => {
      const loggedInUser = localStorage.getItem("token");
      if (loggedInUser) {
        setauthenticated(loggedInUser);
      }
    }, []);
    if(authenticated){
        history.push('/dashboard')
    }
    const handleSubmit = async(event: FormEvent) => {
        //Prevent page reload
        event.preventDefault();
        setIsSubmitting(false)
        if(email==='' || !(/\S+@\S+\.\S+/.test(email))){
            console.log('test')
            setErrorMessages({name:'email',message:'Invalid Email'})
            return;
        }else{
            setErrorMessages({name:'',message:''})
        }
        if(!password || (password && password.length<6)){
            setErrorMessages({name:'password',message:'Password must contain at least 6 characters'})
            return;
        }else{
            setErrorMessages({name:'',message:''})
        }
        setIsSubmitting(true)
        const loginResponse  = await login(email,password);
        setIsSubmitting(false)
        if(!loginResponse){
            setErrorMessages({name:'password',message:'Invalid email or password'})
            return;
        }
        localStorage.setItem('token',loginResponse.access_token)
        setToken(loginResponse.access_token)
        history.push("/dashboard");
    };

    // Generate JSX code for error message
    const renderErrorMessage = (name: string) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );
    
    return (
        <MDBContainer>
            <MDBRow className='mt-5'>
                <MDBCol size='4'></MDBCol>
                <MDBCol size='4'>
                    <MDBCard>
                        <MDBCardBody>
                            <MDBCardTitle>Login</MDBCardTitle>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <MDBInput  type="email" name="email" label="Email" value={email} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setEmail(e.target.value)}} required />
                                    {renderErrorMessage("email")}
                                </div>
                                <div className="input-container">
                                    <MDBInput  type="password" name="password" label="Password" value={password} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{setPassword(e.target.value)}} required />
                                    {renderErrorMessage("password")}
                                </div>
                                <div className="button-container">
                                    { isSubmitting?
                                       
                                            <span className='visually-hidden'>Loading...</span>
                                    :<MDBBtn type="submit">Login</MDBBtn>}
                                </div>
                            </form>
                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>
                <MDBCol size='4'></MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}
