import React, { Component, Fragment, useState, useEffect } from 'react';

import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBBtnGroup } from "mdbreact";
import { MDBRow, MDBCol, MDBView, MDBCard, MDBCardBody, MDBTable, MDBTableHead, MDBTableBody } from 'mdbreact';

import HomePageTabs from "../companydetails/homepage/index";
import { useRouter } from '../../hooks/router/userRouter';
import ContactFormPage from '../companydetails/contact/contact-form';
import WorkPageTabs from '../companydetails/workpage';

import {getCompany} from "../../Api/companyApi";

import { CompanyProvider } from "../companydetails/homepage/context/companyContext";
import AboutTabs from '../companydetails/about';
import MediaList from '../companydetails/media/media-list';

const DashboardPage = (props: any) => {
  
  const [activeItem, setActiveItem] = useState('');
  useEffect( 
    () => {
      setActiveItem(query.tab);
    }
  );
  const { companyId } = props.match.params;

  const [company, setCompany] = useState(null);
  useEffect( 
    () => {
      getCompany(companyId)
          .then((res: any) => {
            setCompany(res);
          })
          .catch((err: any) => {
            alert('Error to get  company' + err)
          });
    }, companyId
  );

  const { query, push } = useRouter();

  
  const toggle = (tab: any) => (e: any) => {
    if (activeItem !== tab) {
      setActiveItem(tab);
    }
  };

  const renderNavTabs = (companyId: string) => {
    return (
      <Fragment>
        <MDBNav className="nav-tabs">

          <MDBNavItem>
              <MDBNavLink to={`/dashboard/company/${companyId}/homepage=1`} active={activeItem === 'homepage'} onClick={toggle('homepage')} role="tab" >
                Home Page
              </MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
            <MDBNavLink to={`/dashboard/company/${companyId}/about=1`} active={activeItem === 'about'} onClick={toggle('about')} role="tab" >
              About
            </MDBNavLink>
          </MDBNavItem>

          

          <MDBNavItem>
            <MDBNavLink to={`/dashboard/company/${companyId}/works=1`} active={activeItem === 'works'} onClick={toggle('works')} role="tab" >
              Work(s)
            </MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
            <MDBNavLink to={`/dashboard/company/${companyId}/contact=1`} active={activeItem === 'contact'} onClick={toggle('contact')} role="tab" >
              Contact
            </MDBNavLink>
          </MDBNavItem>

          <MDBNavItem>
              <MDBNavLink to={`/dashboard/company/${companyId}/media=1`} active={activeItem === 'media'} onClick={toggle('media')} role="tab" >
                Media
              </MDBNavLink>
          </MDBNavItem>
          

        </MDBNav>
      </Fragment>
    )
  }

  const CompanyIdContext = React.createContext('');

  const renderNavTabContent = (companyId: string, query: any) => {
    
    return (
      <Fragment>
        <MDBTabContent activeItem={activeItem} >
          {activeItem === '' &&
            <>
              <MDBTabPane tabId='' role="tabpanel" path={`/dashboard/company/${companyId}/about`} >

                <MDBContainer>
                  <MDBRow>
                    <MDBCol >
                      Hello
                                  </MDBCol>
                  </MDBRow>
                </MDBContainer>
              </MDBTabPane>
            </>
          }
          {activeItem === 'about' &&
            <>
              <MDBTabPane tabId='about' role="tabpanel" path={`/dashboard/company/${companyId}/about=1`}>

                <AboutTabs company={company} companyId={companyId} loading={props.loading} />
              </MDBTabPane>
            </>
          }
          {activeItem === 'homepage' &&
            <>
              <MDBTabPane tabId='homepage' role="tabpanel" to={`/dashboard/company/${companyId}/homepage=1`}>
                <CompanyProvider value={companyId}>
                  <HomePageTabs company={company} companyId={companyId} loading={props.loading} />
                </CompanyProvider>
                
              </MDBTabPane>
            </>}

          {activeItem === 'works' && 
            <>
            <MDBTabPane tabId='works' role="tabpanel" to={`/dashboard/company/${companyId}/works=1`}>
            <CompanyProvider value={companyId}>
              <WorkPageTabs company={company} companyId={companyId} loading={props.loading}/>
              </CompanyProvider>
            </MDBTabPane>
            </>
          }

          {activeItem === 'contact' && 
            <>
            <MDBTabPane tabId='contact' role="tabpanel" to={`/dashboard/company/${companyId}/contact=1`}>
              <ContactFormPage company={company} companyId={companyId} companyName={props.name}/>
            </MDBTabPane>
            </>
          }

          {activeItem === 'media' && 
            <>
            <MDBTabPane tabId='media' role="tabpanel" to={`/dashboard/company/${companyId}/media=1`}>
              <MediaList company={company} companyId={companyId} companyName={props.name}/>
            </MDBTabPane>
            </>
          }
        </MDBTabContent>
      </Fragment>
    )
  }


    

    return (
      <div>
        {renderNavTabs(companyId)}
        {renderNavTabContent(companyId, query)}
      </div>

    );
}

export default DashboardPage;
