import React from "react";
import SideNavigation from "../navigation/sideNavigation";
import {  MDBIcon } from 'mdbreact';
import { useHistory } from "react-router";

const DashboardLayout = ({ children }) => {
    const history = useHistory()
    const logout = ()=>{
        localStorage.clear()
        history.push('/login')
        console.log('test')
    }
    return <div className="flexible-content">
        <div>
            <button type="button" className="btn btn-info" style={{ float: "right", margin: "1rem" }} onClick={()=>logout()}>Logout  <MDBIcon fas icon="sign-out-alt" className="ml-2" /></button>
            <SideNavigation />
            <main id="content" className="p-5">
                {children}
            </main>
        </div>
    </div>

}
export default DashboardLayout;