import axios from '../Axios/index';
import { config } from '../config/common';

export const postCompany = async (data: any) => {
    return await axios.request({
        url: `/api/company`,
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' }
    }).then((res: any) => {
        return res.data
    })
}

export const getAllCompany = async () => {
    return await axios.request({
        url: `/api/company`,
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const updateCompanyApi = async (id: String, data: any) => {

    return await axios.request({
        url: `/api/company/${id}`,
        method: 'post',
        data: data,
        headers: { 'Access-Control-Allow-Origin': "*",'Content-Type': 'multipart/form-data'}
    }).then((res: any) => {
        return res.data
    })
}

export const getCompany = async (id: any) => {
    return await axios.request({
        url: `/api/company/${id}`,
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    }).then((res: any) => {
        return res.data
    })
}

export const deleteCompany =  async (companyId: any) => {
    return await axios.request({
        url: `/api/company/${companyId}`,
        method: 'delete'
    })
    .then((res: any) => {
        return res.data
    })
}