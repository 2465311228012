import React from "react";
import {Redirect, Route} from "react-router-dom";

export const RouteGuard = ({component: Component, routeRedirect, ...rest}) => (
  <Route {...rest} render={props => (
      localStorage.getItem('token') ?
      <Component {...props}/> :
      <Redirect to="/login" />
  )}/>
);


